<!--
 * @Description: 侧边栏
 * @Author: sunxiaodong
 * @Date: 2020-04-03 14:25:10
 * @LastEditors: ChenXueLin
 * @LastEditTime: 2022-02-22 14:25:24
 -->
<template>
  <el-scrollbar class="scrollbar-wrapper">
    <el-menu
      :class="objClass"
      :collapse="collapse"
      :mode="mode"
      :default-active="activeMenu"
      :unique-opened="true"
      :text-color="menuTextColor"
      :active-text-color="menuActiveTextColor"
      :collapse-transition="false"
      @select="handleSelect"
      ref="menu"
    >
      <menu-item
        v-for="(item, index) in menu"
        :item="item"
        :key="item.meta.url + '-' + index"
        :isIcon="true"
      ></menu-item>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import {
  menuTextColor,
  menuActiveTextColor
} from "@/assets/styles/variables.scss";
import MenuItem from "./MenuItem";
import { mapGetters } from "vuex";

export default {
  components: {
    MenuItem
  },

  props: {
    mode: {
      type: String
    }
  },

  data() {
    return {
      menuTextColor,
      menuActiveTextColor
    };
  },

  computed: {
    ...mapGetters(["sidebar", "menu"]),
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },

    objClass() {
      return {
        "el-menu-special": true,
        "el-menu--vertical": this.mode === "vertical"
      };
    },

    collapse() {
      if (this.mode === "horizontal") {
        return false;
      }
      return !this.sidebar.opened;
    }
  },

  methods: {
    handleSelect(item) {
      this.$store.dispatch("tagsView/selectMenu", item);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/variables.scss";

/deep/ {
  .el-menu {
    border: none !important;
    background-color: transparent !important;

    .menu-item__title {
      display: block;
    }

    .el-menu-item,
    .el-submenu,
    .el-submenu__title {
      background-color: transparent !important;
    }

    .el-submenu__title:hover,
    .el-menu-item:hover {
      background-color: rgba(255, 255, 255, 0.1) !important;
    }
  }
}

/*********************** 垂直菜单 ************************/
.vertical {
  .scrollbar-wrapper {
    height: 100%;
    /deep/ .el-scrollbar__wrap {
      // overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

/deep/ {
  .el-menu.el-menu--vertical {
    width: $menu-width--v;
    &.el-menu--collapse {
      width: $menu-collapse-width--v;
      .el-menu-item,
      .el-submenu__title,
      .el-tooltip {
        width: $menu-collapse-width--v;
        padding: 0 !important;
        text-align: center;
        i {
          margin: 0;
        }
      }

      .el-submenu__title {
        & > span {
          height: 0;
          width: 0;
          overflow: hidden;
          visibility: hidden;
          display: inline-block;
        }
        .el-submenu__icon-arrow {
          display: none;
        }
      }
    }

    .el-menu-item.is-active {
      background-color: $menu-item-active-background-color--v !important;
    }
    &.el-menu--collapse {
      .el-submenu.is-active {
        & > .el-submenu__title {
          background-color: $menu-item-active-background-color--v !important;
        }
      }
    }

    & > .menu-item {
      border-bottom: 1px solid #313946;
    }

    .menu-item--3 {
      .el-menu-item {
        padding-left: 50px !important;
        padding-right: 0 !important;
      }
    }

    .el-menu-item.is-disabled {
      opacity: initial;
      cursor: pointer;
    }
  }

  // 收起
}

/********************* 垂直菜单 end **********************/

/*********************** 水平菜单 ************************/
.horizontal {
  .scrollbar-wrapper {
    /deep/ .el-scrollbar__bar {
      &.is-horizontal,
      &.is-vertical {
        display: none;
      }
    }
  }
}

/deep/ {
  .el-menu.el-menu--horizontal {
    display: flex;

    & > .menu-item {
      .el-submenu__icon-arrow {
        position: static;
        vertical-align: middle;
        margin-left: 8px;
        margin-top: -3px;
      }
    }

    .el-menu-item.is-active {
      background-color: $menu-item-active-background-color--v !important;
    }
    .el-submenu.is-active {
      & > .el-submenu__title {
        background-color: $menu-item-active-background-color--v !important;
      }
    }
  }
}
/********************* 水平菜单 end **********************/
</style>

<style lang="scss">
@import "~@/assets/styles/variables.scss";

@mixin menu {
  .el-menu-item,
  .el-submenu__title {
    height: $menu-height-title;
    line-height: $menu-height-title;
    i {
      color: inherit;
      margin-right: 0;
    }
    i:first-child {
      margin-right: 5px;
    }
    a {
      color: inherit;
    }
    * {
      vertical-align: initial;
    }
  }
}
.el-menu {
  @include menu;
  &.el-menu--popup {
    background-color: $menu-background-color;
    .el-menu-item,
    .el-submenu,
    .el-submenu__title {
      height: $menu-height-item;
      line-height: $menu-height-item;
      font-size: 16px;
      background-color: $menu-background-color;
      font-size: 12px;
    }

    /* 选中菜单颜色 */
    .el-menu-item.is-active {
      background-color: $menu-item-active-background-color--v !important;
    }
    .el-menu-item.is-disabled {
      opacity: initial;
      cursor: pointer;
    }
    &.el-menu--collapse {
      .el-submenu.is-active {
        & > .el-submenu__title {
          background-color: $menu-item-active-background-color--v !important;
        }
      }
    }
    .menu-item {
      .el-submenu {
        &.is-active > .el-submenu__title {
          color: $--color-primary !important;
        }
      }
    }
  }
}
</style>
