export default {
  props: {
    to: {
      type: String,
      required: true
    },
    target: String,
    title: String,
    icon: String,
    menuType: String,
    isIcon: Boolean
  },

  render: function(h) {
    // let target = this.target === "_blank";

    let target = this.menuType === "vue" || this.target === "_blank";

    return h(
      target ? "a" : "router-link",
      {
        class: "menu-item__title",
        props: {
          to: this.to
        },
        attrs: target
          ? {
              href: this.to,
              target: "_blank"
            }
          : {}
      },
      this.$slots.default
    );
  }
};
