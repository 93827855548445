<!--
 * @Description: 
 * @Author: sunxiaodong
 * @Date: 2020-04-09 16:12:01
 * @LastEditors: ChenXueLin
 * @LastEditTime: 2021-08-16 17:43:24
 -->
<template>
  <div class="menu-item" :class="`menu-item--${level}`">
    <el-submenu
      v-if="item.children && item.children.length"
      :index="`${item.meta.url}${item.menuKey}`"
    >
      <template slot="title">
        <template v-if="item.meta.icon && layout === 'vertical' && isIcon">
          <i :class="item.meta.icon"></i>
        </template>
        <span>{{ item.meta.title }}</span>
      </template>

      <template v-if="item.children">
        <menu-item
          v-for="subItem in item.children"
          :item="subItem"
          :key="`${subItem.meta.url}${subItem.menuKey}`"
          :level="_level()"
        ></menu-item>
      </template>
    </el-submenu>

    <app-link
      v-else
      :to="item.meta.url"
      :target="item.meta.target"
      :title="item.meta.title"
      :icon="item.meta.icon"
      :menuType="item.meta.menuType"
      :isIcon="isIcon"
    >
      <el-menu-item
        :index="item.meta.url"
        :disabled="
          item.meta.target === '_blank' || item.meta.menuType === 'vue'
        "
      >
        <template v-if="item.meta.icon && layout === 'vertical' && isIcon">
          <i :class="item.meta.icon"></i>
        </template>
        <span slot="title">{{ item.meta.title }}</span>
      </el-menu-item>
    </app-link>
  </div>
</template>

<script>
import appLink from "./appLink";

export default {
  name: "MenuItem",

  components: {
    appLink
  },

  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isIcon: {
      type: Boolean,
      required: false
    },
    // 菜单层级
    level: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      layout: "vertical"
    };
  },

  methods: {
    _level() {
      return this.level + 1;
    }
  }
};
</script>
